import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import RadioInputOptions from "../../../shared/FormElements/RadioInput/RadioInputOptions";
import RadioInput from "../../../shared/FormElements/RadioInput";
import onChangeHandler from "../../../utils/onChangeHandler";
import { resetError, setError } from "../../../store/Error/epics";
import { setMyAnswers } from "../../../store/Certificate/epics";
import { callRemoveFileFromMyAnswer } from "../../../store/Certificate/actions";
import isAnswerSelected from "../../../utils/isAnswerSelected";
import findObjById from "../../../utils/findObjById";
import FileUploadInput from "../../../shared/FormElements/FileUploadInput";
import TextInput from "../../../shared/FormElements/TextInput";
import FilesList from "../../../shared/FilesList";
import { Card } from "react-bootstrap";
import { options } from "../../../lookups/status";

const SectionsList = ({ section, sectionIndex }) => {
  const location = useLocation();
  let { uuid, companyId } = useParams();
  const local = useSelector((state) => state.homeReducer.local);

  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errorReducer.errors);
  const myAnswers = useSelector((state) => state.certificateReducer.myAnswers);

  const handleResetError = (question_id) => {
    const updatedErrors = {};

    updatedErrors[question_id] = {
      ...updatedErrors[question_id],
      option: "",
    };

    dispatch(setError(updatedErrors));
  };

  const handleChange = (e, question_id) => {
    handleResetError(question_id);
    onChangeHandler(
      e,
      setMyAnswers,
      myAnswers,
      errors,
      dispatch,
      resetError,
      "assesment",
      question_id
    );
  };

  const allSectionQuestions = section.questions.map(
    (question, questionIndex) => {
      const questionOptions = options.map((option) => (
        <RadioInputOptions
          htmlFor={option.value}
          label={local.language === "ar" ? option.option_ar : option.option_en}
          key={option.value}
          question={question}
          onChange={(e) => handleChange(e, question.id)}
          checked={isAnswerSelected(
            myAnswers,
            question.id.toString(),
            option.value.toString()
          )}
          inputValue={option.value.toString()}
          inputName={`value_${question.id.toString()}`}
          inputId={option.value}
        />
      ));

      return (
        <div key={questionIndex}>
          <RadioInput
            inputId={"question_id_" + questionIndex}
            question={
              local.language === "ar" ? question.text_ar : question.text_en
            }
            error={
              errors[question.id.toString()] &&
              errors[question.id.toString()]["option"]
            }
            options={questionOptions}
          />

          {location.pathname === `/${companyId}/certificate/${uuid}/evidence` &&
            findObjById(myAnswers, question.id) && (
              <>
                {findObjById(myAnswers, question.id)["value"] === "YES" ? (
                  <>
                    <p className="evidence mb-1 text-success">
                      {local.evidence} <i className="text-danger">*</i>
                    </p>
                    <FilesList
                      question_id={question.id}
                      state={myAnswers}
                      setState={setMyAnswers}
                      files={
                        findObjById(myAnswers, question.id)
                          ? findObjById(myAnswers, question.id).evidences
                          : []
                      }
                      filesName={"evidences"}
                      deleteFn={callRemoveFileFromMyAnswer}
                    />
                    <div className="mt-2">
                      <FileUploadInput
                        multiple={true}
                        inputId={"recipt_file_input"}
                        disabledInputId={"recipt_file_disabled_input"}
                        label={
                          findObjById(myAnswers, question.id).evidences?.length
                            ? local.uploadAnotherFile
                            : local.uploadFile
                        }
                        inputName={"evidences"}
                        // inputValue={}
                        // FileName={state.file.name}
                        // width={state.file.name.length * 7}
                        error={
                          errors[question.id.toString()] &&
                          errors[question.id.toString()]["evidences"]
                        }
                        onChange={(e) => handleChange(e, question.id)}
                        inputClass={"disabled-input d-none"}
                        style={
                          findObjById(myAnswers, question.id).evidences?.length
                            ? "btn btn--outlined"
                            : "btn btn--upload"
                        }
                      />
                    </div>
                  </>
                ) : (
                  findObjById(myAnswers, question.id)["value"] ===
                    "NOT_APPLICABLE" && (
                    <>
                      <p className="evidence mb-1 text-success">
                        {local.justification} <i className="text-danger">*</i>
                      </p>
                      <TextInput
                        inputId="add_engineer_first_name"
                        placeholder={local.pleaseJustifyYourAnswer}
                        inputName={"justification"}
                        inputValue={
                          findObjById(myAnswers, question.id)
                            ? findObjById(myAnswers, question.id).justification
                              ? findObjById(myAnswers, question.id)
                                  .justification !== "null"
                                ? findObjById(myAnswers, question.id)
                                    .justification
                                : ""
                              : ""
                            : ""
                        }
                        error={
                          errors[question.id.toString()] &&
                          errors[question.id.toString()]["justification"]
                        }
                        onChange={(e) => handleChange(e, question.id)}
                      />
                    </>
                  )
                )}
                {question.answer.note && (
                  <div className="text-success border-success border p-2 border-2 rounded-1">
                    <span>
                      {local.auditorComment} :{question.answer.note}
                    </span>
                  </div>
                )}
              </>
            )}

          {section.questions.length - 1 !== questionIndex && (
            <hr className="my-4" />
          )}
        </div>
      );
    }
  );

  return (
    <Card key={sectionIndex} className="shadow my-5 p-4">
      <h5>{local.language === "ar" ? section?.name_ar : section?.name_en}</h5>
      {allSectionQuestions}
    </Card>
  );
};
export default SectionsList;

import { useState, useCallback } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import BackButton from "../../../../shared/BackButton";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../../../../shared/FormElements/TextInput";
import Loader from "../../../Common/Loader";
import DropDownInput from "../../../../shared/FormElements/DropDownInput";
import MobileInput from "../../../../shared/FormElements/MobileInput";
import isEmpty from "../../../../utils/isEmpty";
import onChangeHandler from "../../../../utils/onChangeHandler";
import formValidaiton from "../../../../utils/formValidaiton";
import { resetErrors, resetError } from "../../../../store/Error/epics";
import LeafletMap from "../../../../shared/LeafletMap";
import FileUploadInput from "../../../../shared/FormElements/FileUploadInput";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { callRegister } from "../../../../store/Auth/actions";
import { fetchCitiesList } from "../../../../store/Home/actions";
import { toast } from "react-toastify";

function RegistrationForm() {
  const local = useSelector((state) => state.homeReducer.local);
  const errors = useSelector((state) => state.errorReducer.errors);
  const regionsList = useSelector((state) => state.homeReducer.regionsList);
  const citiesList = useSelector((state) => state.homeReducer.citiesList);
  const loading = useSelector((state) => state.commonReducer.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [entity, setEntity] = useState({
    nameAr: "",
    nameEn: "",
    representative: "",
    representativeId: "",
    representativeEmail: "",
    representativeMobile: "",
    city: "",
    location: { lat: "", lng: "" },
    NofEmployees: "",
    sevenHundred: "",
    district: "",
    street: "",
    postalCode: "",
    buildingNumber: "",
    secondaryNumber: "",
    letter: null,
    representativeIdCopy: null,
    recaptcha: "",
  });
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const [agreed, setAgreed] = useState(false);

  const accept = [".pdf", ".png", ".jpg", ".gif"];

  useLegacyEffect(() => {
    dispatch(resetErrors());
  }, []);
  useLegacyEffect(() => {
    if (errors.non_field_errors) {
      toast.error(errors.non_field_errors);
    }
  }, [errors]);

  const regionsListOptions =
    (!isEmpty(regionsList) &&
      regionsList.map((region) => ({
        value: region.id,
        label: local.language === "ar" ? region?.name_ar : region?.name_en,
      }))) ||
    [];

  const citiesListOptions =
    (!isEmpty(citiesList) &&
      citiesList.map((city) => ({
        value: city.id,
        label: local.language === "ar" ? city?.name_ar : city?.name_en,
      }))) ||
    [];

  const handleMarkerDragEnd = (point) => {
    setEntity((state) => ({
      ...state,
      location: point,
    }));
    dispatch(resetError("latitude"));
  };

  function handleOnChange(event) {
    onChangeHandler(event, setEntity, entity, errors, dispatch, resetError);
  }

  const handleSelectedRegion = (data) => {
    setSelectedRegionId(data);
    setEntity((prev) => ({
      ...prev,
      city: "",
    }));
    dispatch(fetchCitiesList(data.value));
  };

  const handleSelectedCity = (data) => {
    setEntity((prev) => ({
      ...prev,
      city: data,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEntity((prev) => ({
      ...prev,
      [e?.target?.name]: file,
    }));
    dispatch(resetError(e?.target?.name));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationFormData = {
      nameAr: {
        value: entity.nameAr,
        type: "text",
        required: true,
      },
      nameEn: {
        value: entity.nameAr,
        type: "text",
        required: true,
      },
      representative: {
        value: entity.representative,
        type: "text",
        required: true,
      },
      representativeId: {
        value: entity.representativeId,
        type: "nin",
        required: true,
      },
      representativeEmail: {
        value: entity.representativeEmail,
        type: "email",
        required: true,
      },
      representativeMobile: {
        value: entity.representativeMobile,
        type: "mobile",
        required: true,
      },
      NofEmployees: {
        value: entity.NofEmployees,
        type: "number",
        required: true,
      },
      sevenHundred: {
        value: entity.sevenHundred,
        type: "700",
        required: true,
      },
      region: {
        value: selectedRegionId,
        type: "text",
        required: true,
      },
      city: {
        value: entity.city,
        type: "text",
        required: true,
      },
      district: {
        value: entity.district,
        type: "text",
        required: true,
      },
      street: {
        value: entity.street,
        type: "text",
        required: true,
      },
      postalCode: {
        value: entity.postalCode,
        type: "postal",
        required: true,
      },
      buildingNumber: {
        value: entity.buildingNumber,
        type: "number",
        required: true,
      },
      secondaryNumber: {
        value: entity.secondaryNumber,
        type: "number",
        required: true,
      },
      latitude: { value: entity.location.lat, type: "text", required: true },
      longitude: { value: entity.location.lng, type: "text", required: true },
      representativeIdCopy: {
        value: entity.representativeIdCopy,
        type: "file",
        required: true,
      },
      letter: {
        value: entity.letter,
        type: "file",
        required: true,
      },
    };
    const isValidForm = formValidaiton(validationFormData, dispatch);

    if (isValidForm) {
      let token = "";
      try {
        token = (await executeRecaptcha("registration")) || "";
      } catch (e) {
        console.error(e);
      }
      const formData = new FormData();

      formData.append("company_name_ar", entity.nameAr);
      formData.append("company_name_en", entity.nameEn);
      formData.append("representative_national_id", entity.representativeId);
      formData.append("representative_email", entity.representativeEmail);
      formData.append(
        "representative_phone_number",
        entity.representativeMobile
      );
      formData.append("city", entity.city.value);
      formData.append("authorization_letter", entity.letter);
      formData.append(
        "representative_national_id_document",
        entity.representativeIdCopy
      );
      formData.append("number_of_employees", entity.NofEmployees);
      formData.append("seven_hundred", entity.sevenHundred);
      formData.append("latitude", entity.location.lat);
      formData.append("longitude", entity.location.lng);
      formData.append("district", entity.district);
      formData.append("street", entity.street);
      formData.append("postal_code", entity.postalCode);
      formData.append("building_number", entity.buildingNumber);
      formData.append("secondary_number", entity.secondaryNumber);
      formData.append("recaptcha", token);

      dispatch(callRegister(navigate, formData, local));
    } else {
      if (agreed) {
        window.scrollTo(0, 0);
        if (entity.location.lat === "") {
          toast.error(local.fillMapFeild);
        } else {
          toast.error(local.correctFormErrors);
        }
      } else {
        toast.error(local.correctFormErrors);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={8}>
          <Card className="shadow w-100 px-4 py-5 px-lg-5 mb-5">
            <Row>
              <h5 className="text-success fw-bold">{local.basicInfo}</h5>
              <Col xs={12} md={6}>
                <TextInput
                  inputId={"nameAr"}
                  type={"text"}
                  label={local.companyName_ar}
                  inputName={"nameAr"}
                  inputValue={entity.nameAr}
                  isInvalid={errors.nameAr}
                  error={errors.nameAr}
                  onChange={handleOnChange}
                  required={true}
                  maxLength={20}
                  labelClass={"text-primary"}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextInput
                  inputId={"nameEn"}
                  type={"text"}
                  label={local.companyName_en}
                  inputName={"nameEn"}
                  inputValue={entity.nameEn}
                  isInvalid={errors.nameEn}
                  error={errors.nameEn}
                  onChange={handleOnChange}
                  required={true}
                  maxLength={20}
                  labelClass={"text-primary"}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <TextInput
                  inputId={"NofEmployees"}
                  type={"number"}
                  label={local.numberOfEmployees}
                  inputName={"NofEmployees"}
                  inputValue={entity.NofEmployees}
                  isInvalid={errors.NofEmployees}
                  error={errors.NofEmployees}
                  onChange={handleOnChange}
                  required={true}
                  labelClass={"text-primary"}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextInput
                  inputId={"sevenHundred"}
                  type={"number"}
                  label={local.sevenHundred}
                  inputName={"sevenHundred"}
                  inputValue={entity.sevenHundred}
                  isInvalid={errors.sevenHundred}
                  error={errors.sevenHundred}
                  onChange={handleOnChange}
                  required={true}
                  maxLength={10}
                  labelClass={"text-primary"}
                />
              </Col>
              <hr />
            </Row>
            <Row>
              <h5 className="text-success fw-bold">
                {local.representativeIfo}
              </h5>
              <TextInput
                inputId={"representative"}
                type={"text"}
                label={local.representativeName}
                inputName={"representative"}
                inputValue={entity.representative}
                isInvalid={errors.representative}
                error={errors.representative}
                onChange={handleOnChange}
                labelClass={"text-primary"}
                required={true}
                maxLength={20}
              />
              <Col xs={12} md={6}>
                <TextInput
                  inputId={"representativeId"}
                  type={"number"}
                  label={local.representativeId}
                  inputName={"representativeId"}
                  inputValue={entity.representativeId}
                  isInvalid={errors.representativeId}
                  error={errors.representativeId}
                  onChange={handleOnChange}
                  required={true}
                  labelClass={"text-primary"}
                  maxLength={10}
                />
              </Col>
              <Col xs={12} md={6}>
                <MobileInput
                  labelClass={"text-primary"}
                  inputId={"representativeMobile"}
                  type={"text"}
                  label={local.representativeMobile}
                  inputName={"representativeMobile"}
                  inputValue={entity.representativeMobile}
                  isInvalid={errors.representativeMobile}
                  error={errors.representativeMobile}
                  onChange={handleOnChange}
                  required={true}
                />
              </Col>
              <TextInput
                inputId={"representativeEmail"}
                type={"email"}
                label={local.representativeEmail}
                placeholder={local.emailDomain}
                inputName={"representativeEmail"}
                inputValue={entity.representativeEmail}
                isInvalid={errors.representativeEmail}
                error={errors.representativeEmail}
                onChange={handleOnChange}
                required={true}
                labelClass={"text-primary"}
              />
              <hr />
            </Row>
            <Row>
              <h5 className="text-success fw-bold">{local.companyAddress}</h5>
              <Row>
                <Col xs={12} md={6}>
                  <DropDownInput
                    isregion={true}
                    labelClass={"text-primary"}
                    inputId="region"
                    options={regionsListOptions}
                    label={local.region}
                    inputName={"region"}
                    inputValue={selectedRegionId}
                    isInvalid={errors.region}
                    error={errors.region}
                    onChange={handleSelectedRegion}
                    required={true}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <DropDownInput
                    iscity={true}
                    inputId="city"
                    options={citiesListOptions}
                    label={local.city}
                    inputName={"city"}
                    inputValue={entity.city}
                    isInvalid={errors.city}
                    error={errors.city}
                    onChange={handleSelectedCity}
                    required={true}
                    labelClass={"text-primary"}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <TextInput
                    inputId={"street"}
                    labelClass={"mt-3 text-primary"}
                    type={"text"}
                    label={local.street}
                    inputName={"street"}
                    inputValue={entity.street}
                    isInvalid={errors.street}
                    error={errors.street}
                    onChange={handleOnChange}
                    required={true}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextInput
                    inputId={"buildingNumber"}
                    type={"number"}
                    labelClass={"mt-3 text-primary"}
                    label={local.buildingNumber}
                    inputName={"buildingNumber"}
                    inputValue={entity.buildingNumber}
                    isInvalid={errors.buildingNumber}
                    error={errors.buildingNumber}
                    onChange={handleOnChange}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <TextInput
                    inputId={"district"}
                    type={"text"}
                    label={local.district}
                    inputName={"district"}
                    inputValue={entity.district}
                    isInvalid={errors.district}
                    error={errors.district}
                    onChange={handleOnChange}
                    required={true}
                    labelClass={"text-primary"}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextInput
                    inputId={"postalCode"}
                    type={"number"}
                    label={local.postalCode}
                    inputName={"postalCode"}
                    inputValue={entity.postalCode}
                    isInvalid={errors.postalCode}
                    error={errors.postalCode}
                    onChange={handleOnChange}
                    required={true}
                    labelClass={"text-primary"}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    inputId={"secondaryNumber"}
                    type={"number"}
                    label={local.secondaryNumber}
                    inputName={"secondaryNumber"}
                    inputValue={entity.secondaryNumber}
                    isInvalid={errors.secondaryNumber}
                    error={errors.secondaryNumber}
                    onChange={handleOnChange}
                    required={true}
                    labelClass={"text-primary"}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-primary">
                    {local.entityLocation}
                    <span className="text-danger mx-1">*</span>
                  </p>
                  <LeafletMap
                    position={{
                      lat: entity.location.lat || 24.652,
                      lng: entity.location.lng || 46.7138,
                    }}
                    zoom={15}
                    scrollWheelZoom={true}
                    draggable={true}
                    currentLocationBtn={true}
                    setPosition={handleMarkerDragEnd}
                    error={errors.latitude}
                  />
                </Col>
              </Row>
              <hr className="mt-4" />
            </Row>
            <Row>
              <h5 className="text-success fw-bold">{local.attachments}</h5>
              <p className="small">{local.fileFormat}</p>
              <h6>
                {local.representativeIdCopy}{" "}
                <span className="text-danger mx-1">*</span>
              </h6>
              <FileUploadInput
                label={local.attachFile}
                groupDir={local.language === "ar" ? "rtl" : "ltr"}
                inputValue={entity.representativeIdCopy}
                inputName={"representativeIdCopy"}
                variant={"secondary"}
                inputClass={"text-white"}
                multiple={false}
                onChange={handleFileChange}
                required={true}
                accept={accept}
                isButton={true}
                error={errors.representativeIdCopy}
              />
              <h6>
                {local.uploadLetter} <span className="text-danger mx-1">*</span>
              </h6>
              <FileUploadInput
                label={local.attachFile}
                groupDir={local.language === "ar" ? "rtl" : "ltr"}
                inputValue={entity.letter}
                inputName={"letter"}
                variant={"secondary"}
                inputClass={"text-white"}
                multiple={false}
                onChange={handleFileChange}
                required={true}
                accept={accept}
                isButton={true}
                error={errors.letter}
              />
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={8} className="text-center">
          <Form.Check
            type="checkbox"
            id="terms-checkbox"
            className="d-flex align-items-center mb-3"
            label={
              <span>
                {local.agree}
                <Link to="/terms-condition" className="text-secondary">
                  {local.termsConditions}
                </Link>
              </span>
            }
            checked={agreed}
            onChange={(e) => {
              setAgreed(e.target.checked);
            }}
          />
          <Button
            variant="secondary"
            className="text-white w-75"
            onClick={handleSubmit}
            disabled={!agreed}
          >
            {local.saveAndSend}
          </Button>
          <p className="text-center my-4">
            <span className="mx-2 d-inline-block">{local.haveAccount}</span>
            <Link className="text-secondary " to={"/auth/government/login"}>
              {local.signIn}
            </Link>
          </p>
        </Col>
      </Row>
    </>
  );
}

function GovernmentRegistration() {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();

  return (
    <Container>
      <BackButton
        onClick={() => navigate("/auth/government/login")}
        label={local.backV2}
      />
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={8}>
          <p className="fs-3 text-primary font-weight-bold">
            {local.governmentCreateAccount}
          </p>
        </Col>
      </Row>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <RegistrationForm />
      </GoogleReCaptchaProvider>
    </Container>
  );
}

export default GovernmentRegistration;

import * as types from "./types";

const initialState = {
  criteriaIds: [],
  myAnswers: [],
  currentOrders: [],
  oldOrders: [],
  currentPageQuestionsIds: [],
  assessmentOrder: {},
  currentCertificateFlowStep: 0,
  flowSteps: [
    { id: 1, text: "التقييم الذاتي", text_en: "Self Assisment" },
    { id: 2, text: "دفع الرسوم", text_en: "Pay Fees" },
    { id: 3, text: "إرفاق الأدلة والتوضيحات", text_en: "Upload Evidence" },
    { id: 4, text: "التدقيق", text_en: "Auditing" },
    { id: 5, text: "النتيجة", text_en: "Result" },
  ],
  preview: {},
  certificate: null,
  sections: [],
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ASSESSMENT_ORDER:
      return {
        ...state,
        assessmentOrder: action.payload,
      };
    case types.SET_CURRENT_CERTIFICATE_FLOW_STEP:
      return {
        ...state,
        currentCertificateFlowStep: action.payload,
      };
    case types.SET_CURRENT_PAGE_QUESTIONS_IDS:
      return {
        ...state,
        currentPageQuestionsIds: action.payload,
      };
    case types.SET_CRITERIA_IDS:
      return {
        ...state,
        criteriaIds: action.payload,
      };
    case types.SET_MY_ANSWERS:
      return {
        ...state,
        myAnswers: action.payload,
      };
    case types.REMOVE_FILE_FROM_MY_ANSWER:
      return {
        ...state,
        myAnswers: action.payload,
      };
    case types.SET_CURRENT_ORDERS:
      return {
        ...state,
        currentOrders: action.payload,
      };
    case types.SET_OLD_ORDERS:
      return {
        ...state,
        oldOrders: action.payload,
      };
    case types.SET_PREVIEW:
      return {
        ...state,
        preview: action.payload,
      };
    case types.SET_CERTIFICATE:
      return {
        ...state,
        certificate: action.payload,
      };
    case types.SET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };
    default:
      return state;
  }
};

export default certificateReducer;

import isEmpty from "./isEmpty";

const formatData = (sections) => {
  if (!isEmpty(sections)) {
    return sections.flatMap((section) =>
      section.questions.map((question) => ({
        question_id: question.id,
        value: question.answer.value || "",
        justification: question.answer.justification || "",
        evidences: question.answer.evidences || [],
        note: question.answer.note || "",
      }))
    );
  } else {
    return [];
  }
};

export default formatData;

import * as epics from "./epics";
import { AuthInstance, PublicInstance } from "../../utils/axiosConfig";
import {
  setAccessTokenInStorage,
  setRefreshTokenInStorage,
  setUserDataInStorage,
  setIsOIDCAuth,
  setOidcState,
  setFromLocation,
} from "../../utils/localStorage";
import { setErrors } from "../Error/actions";
import { resetErrors } from "../Error/epics";
import { setLoading, setTokenLoading } from "../Common/actions";
import { jwtDecode } from "jwt-decode";
import { generatePKCEandNONCEandState } from "oidc_pkce";
import { changeCurrentLanguage } from "../Home/epics";
import { setCompanyInfo, setMyCompaniesList } from "../Home/epics";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

// this function generate authentication OIDC and redirect user to authentication provider
export const ssoAuthUriGenerator = () => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));

      const pkceData = await generatePKCEandNONCEandState({
        useSTATE: false,
        usePKCE: true,
      });

      const { nonce, code_challenge, code_challenge_method, code_verifier } =
        pkceData;

      const response = await PublicInstance.get(
        `oidc/auth/?nonce=${nonce}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}`
      );
      const data = await response.data;

      setOidcState(JSON.stringify({ nonce, code_verifier }));

      window.location.href = data.redirect_url;
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

// after succeful authentication user will be redirected and this function will validate the user
export const ssoCallBack = (navigate, queryParams) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let payload = {};
      const storedStatesSting = localStorage.getItem("oidc_states");
      if (storedStatesSting) {
        payload = JSON.parse(storedStatesSting);
      }

      let response = await PublicInstance.post(
        `oidc/callback/?${queryParams.toString()}`,
        payload
      );
      let data = response.data;

      setIsOIDCAuth(true);
      setAccessTokenInStorage(data.access);
      setRefreshTokenInStorage(data.refresh);
      dispatch(epics.setIsUserAuthenticated(true));
      dispatch(setLoading(false));

      navigate("/");
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
      // navigate("/")
    }
  };
};

// this function can refresh the token for users from web and OIDC
export const callRefreshToken = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      const isOIDCAuth = localStorage.getItem("is_oidc_auth");
      if (isOIDCAuth) {
        let response = await PublicInstance.post(`oidc/refresh/`, payload);
        let data = await response.data;
        setAccessTokenInStorage(data.access);
        setRefreshTokenInStorage(data.refresh);
      } else {
        let response = await PublicInstance.post(`token/refresh/`, payload);
        let data = await response.data;
        setAccessTokenInStorage(data.access);
        setRefreshTokenInStorage(data.refresh);
        setIsOIDCAuth();
      }

      dispatch(epics.setIsUserAuthenticated(true));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(callLogout());
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

// this function can logout user from web and OIDC
export const callLogout = () => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      const isOIDCAuth = localStorage.getItem("is_oidc_auth");
      const refreshToken = localStorage.getItem("refresh");
      let data;

      if (isOIDCAuth) {
        const response = await PublicInstance.post(`oidc/logout/`);
        data = await response.data;
      } else if (refreshToken) {
        await PublicInstance.post(`token/logout/`, {
          refresh: refreshToken,
        });
      }

      setIsOIDCAuth();
      setAccessTokenInStorage();
      setRefreshTokenInStorage();
      setUserDataInStorage();
      setOidcState();
      setFromLocation();
      dispatch(setCompanyInfo({}));
      dispatch(setMyCompaniesList({}));
      dispatch(epics.setUserInfo({}));
      dispatch(epics.setIsUserAuthenticated(false));
      dispatch(setLoading(false));
      if (isOIDCAuth && data) {
        window.location.href = data.redirect_url;
      }
    } catch (error) {
      setAccessTokenInStorage();
      setRefreshTokenInStorage();
      setUserDataInStorage();
      setOidcState();
      dispatch(setCompanyInfo({}));
      dispatch(epics.setUserInfo({}));
      dispatch(epics.setIsUserAuthenticated(false));
      setIsOIDCAuth();
      dispatch(setLoading(false));
    }
  };
};

// this function is responsible to take email and password and authenticate user
export const callLogin = (navigate, payload) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));

      let response = await PublicInstance.post(`token/`, payload);
      let data = await response.data;

      setAccessTokenInStorage(data.access);
      setRefreshTokenInStorage(data.refresh);

      dispatch(epics.setIsUserAuthenticated(true));
      setIsOIDCAuth();

      dispatch(setLoading(false));
      // toast.success(data.message)
      // navigate("/")
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const callRegister = (navigate, formData, local) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));

      let response = await PublicInstance.post(
        `companies/government/registration/`,
        formData
      );

      dispatch(setLoading(false));
      navigate("/auth/government/register/success");
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchUserInfo = (navigate, payload) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));

      let response = await AuthInstance.get(`users/me/`, payload);
      let data = await response.data;

      dispatch(epics.setUserInfo(data.payload.data));

      dispatch(setLoading(false));

      // navigate("/")
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

// this responsible to check if token or refresh token exist when user refresh the page or open the website and authenticate them
export const checkForExpiredToken = () => {
  return async (dispatch) => {
    dispatch(setTokenLoading(true));
    const access = localStorage.getItem("access");
    const refresh = localStorage.getItem("refresh");
    const prefered_language = localStorage.getItem("prefered_language");
    if (prefered_language) {
      dispatch(changeCurrentLanguage(prefered_language));
      document.body.setAttribute(
        "dir",
        prefered_language === "ar" ? "rtl" : "ltr"
      );
      document.documentElement.setAttribute(
        "dir",
        prefered_language === "ar" ? "rtl" : "ltr"
      );
      document.documentElement.setAttribute(
        "lang",
        prefered_language === "ar" ? "ar" : "en"
      );
    } else {
      localStorage.setItem("prefered_language", "ar");
    }
    if (access && access !== "None" && refresh && refresh !== "None") {
      const currentTime = Date.now() / 1000;
      const accessToken = jwtDecode(access);

      if (accessToken.exp >= currentTime) {
        dispatch(epics.setIsUserAuthenticated(true));
        dispatch(setTokenLoading(false));
      } else {
        await dispatch(callRefreshToken({ refresh: refresh }));
        dispatch(epics.setIsUserAuthenticated(true));
        dispatch(setTokenLoading(false));
      }
    }
    dispatch(setTokenLoading(false));
  };
};

// this function is responsible to take password and confirm password and reset password of user
export const callResetPassword = (navigate, payload) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));

      let response = await PublicInstance.post(`auth/reset-password/`, payload);
      // eslint-disable-next-line no-unused-vars
      let data = await response.data;
      dispatch(setLoading(false));
      navigate("/auth/government/login");
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
// this function is responsible to take email and password
export const callSendOtp = (navigate, payload, navigteRequire) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      dispatch(epics.setOtpInfo(payload));
      let response = await PublicInstance.post(`auth/login/`, payload);
      let data = await response.data;
      if (navigteRequire) navigate("/auth/government/otp");
      dispatch(epics.setOtpInfo(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
// this function is responsible to take Otp for the login
export const callVerifyOtp = (payload, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let response = await PublicInstance.post(`auth/verify/`, payload);
      let data = await response.data;
      setAccessTokenInStorage(data.payload.data.access);
      setRefreshTokenInStorage(data.payload.data.refresh);
      dispatch(epics.setIsUserAuthenticated(true));
      setIsOIDCAuth();
      dispatch(setLoading(false));
    } catch (error) {
      if (
        error?.response?.data?.detail ===
        "The maximum OTP entry attempts has been exceeded"
      ) {
        navigate("/auth/government/login");
      }
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
